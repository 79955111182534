import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const CodePromo = (props) => {
    const { reloadTarifCodePromo, loading, codePromo } = props;

    return (
        <div id="promo" className={`row mt-2 m-lg-5 pb-4`}>
            <div className={'col-12 col-xl-6 offset-xl-3'}>
                <div className={'row align-items-center'}>
                    <div className="col-12 col-md-12 col-md-6 col-xl-5">
                        <p className={'d-block d-sm-none f-20 text-dark fw-bold text-start'}>
                            Vous avez un code promo&nbsp;?
                        </p>
                        <p className={'d-none d-sm-block f-20 text-dark fw-bold text-start'}>
                            Vous avez
                            <br /> un code promo&nbsp;?
                        </p>
                    </div>

                    <div className="col-12 col-md-12 col-md-6 col-xl-7">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[CodePromo][CodePromo]'}
                                    label={'Code promo'}
                                />
                            </div>
                            <div className="col-4">
                                <span
                                    className={'btn btn-outline-primary fw-normal py-2 f-13'}
                                    onClick={() => reloadTarifCodePromo({ codePromo: true })}
                                    style={{ height: 50, marginTop: 24, lineHeight: '32px' }}
                                >
                                    {loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        'Valider'
                                    )}
                                </span>
                            </div>
                        </div>
                        {codePromo?.Eligible === false && (
                            <p className={'text-danger f-14 text-start'}>Le code promo n'est pas valide.</p>
                        )}
                        {codePromo?.Eligible && (
                            <p className={'text-success f-14 text-start'}>Le code promo a bien été appliqué.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodePromo;
