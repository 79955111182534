import {useEffect, useRef, useState} from 'react';
import {Field} from "redux-form";
import _ from 'lodash'
import {Text, Radio} from "@amo/core/components/forms";
import {
    excludeCodePostal,
    required,
    minLentghMarquage,
} from '@amo/core/utils/validateField';
import { minDateHourToday, maxDate30Days } from '@amo/core/utils/validateField';
import { PostalCodeCity } from '@amo/core/components/forms';
import {ChampsObligatoires, QuestionLabel} from '@amo/core/components/containers'
import ScrollingTable from "./ScrollingTable/ScrollingTable";
import moment from "moment";
import {SmallSpinner} from '@amo/core/components/loaders';
import TiersPayeurContainer from "./TiersPayeur/TiersPayeurContainer";
import {normalizeNumMarquage} from "@amo/core/utils/normalize.js";
import {SimpleTitle} from "@amo/core/components/containers";
import IbanField from "@amo/core/components/forms/IbanField/IbanField.jsx";

const Recapitulatif = (props) => {
    const { formValues, codeBelair, ibanIsCheck, isAvenant, loading, changeValue, postalCodeCity, valid, previousStep, isDirect, lienPaiementEmail, postPaySubmit, url_pay, handleSubmit, formule, prixTotalPromo, prixTotal, fractionnement, getTexteBySource, source, nomPrenom, nomTitulaire } = props

    useEffect(() => {
        changeValue('token', url_pay.token);
        changeValue('url_return', url_pay.url_return);
        changeValue('DemandeContrat[DateHeureEffet]', moment().add(5, 'minutes').format('DD/MM/YYYY HH:mm'))
    }, [url_pay.token, url_pay.url_return, changeValue])

    useEffect(() => {
        if (source === "concessionnaire" || (source === "conseiller" && codeBelair === 43397)){
            changeLienPaiement('true')
        }
    }, [])

    const changeLienPaiement = value => {
        if(value === 'true'){
            changeValue('DemandeContrat[ModePaiementComptant]', isAvenant ? 'C' : 'M')
        } else {
            changeValue('DemandeContrat[ModePaiementComptant]', null)
        }
    }
    const verificationTitulaire = value => {
        if(value && _.toUpper(nomPrenom) === _.toUpper(value)){
            changeValue('DemandeContrat[TiersPayeur]', {})
        }
    }

    return (
        <div>
            <SimpleTitle icon={'document'}>{getTexteBySource('votre-recapitulatif')}</SimpleTitle>
            <QuestionLabel className={'my-4'}>Vous avez sélectionné la formule : </QuestionLabel> <span className={'text-primary-light'}>{formule.LibelleFormule}</span>

            <div className={'row my-4'}>
                <div className={'col-12 col-md-8'}>
                    <ScrollingTable formule={formule} prixTotalPromo={prixTotalPromo} prixTotal={prixTotal} fractionnement={fractionnement} getTexteBySource={getTexteBySource}/>
                </div>
            </div>

            <QuestionLabel className={'mt-4'}>Date et heure d'effet&nbsp;<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        name={`DemandeContrat[DateHeureEffet]`}
                        component={Text}
                        type={'text'}
                        typeFormat={'dateHour'}
                        placeholder={'Exemple : 22/03/2023 12:20'}
                        maxLength={16}
                        validate={[maxDate30Days, minDateHourToday]}
                        required
                    />
                </div>
            </div>

            <QuestionLabel className={'mt-4'}>Numéro de marquage (Bicycode, MFC, Paravol, Recobike, Auvray security, Décathlon)</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeContrat[VIN]'}
                        validate={[minLentghMarquage]}
                        normalize={normalizeNumMarquage}
                    />
                </div>
            </div>
            <p className={'m-0'}><small className={'text-danger'}>{getTexteBySource('warningMarquage')}</small></p>

            {
                !isAvenant && <div className={'bg-grey p-4 rounded text-left mt-5'}>
                    <p className={'m-0'} dangerouslySetInnerHTML={{__html: getTexteBySource('souscription')}} />
                </div>
            }

            {
                !isAvenant && <>
                    <QuestionLabel className={'mt-4'}>Nom et prénom du titulaire du compte<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[NomTitulaire]'}
                                normalize={v => v.toUpperCase()}
                                validate={required}
                                onChange={e => verificationTitulaire(e.target.value)}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'mt-4'}>Adresse&nbsp;<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[adrIBAN]'}
                                normalize={v => v.toUpperCase()}
                                validate={required}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'mt-4'}>Ville / Code postal<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'} data-name="DemandeContrat[villeIBAN]">
                            <PostalCodeCity
                                icon={'pin'}
                                id={'villeIban'}
                                nameVille={'DemandeContrat[villeIBAN]'}
                                changeValue={changeValue}
                                nameCodePostal={'DemandeContrat[cpIBAN]'}
                                validate={[required, excludeCodePostal]}
                                defaultValue={postalCodeCity}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'mt-4'}>IBAN<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'} data-name="DemandeContrat[IBAN]">
                            <IbanField formValues={formValues} changeValue={changeValue}/>
                        </div>
                    </div>


                    {
                        (_.toUpper(nomPrenom) !== _.toUpper(nomTitulaire)) && <TiersPayeurContainer {...props}/>
                    }

                    { (source === 'courtier' || (source === 'conseiller' && codeBelair !== 43397)) &&
                        <>
                            <QuestionLabel className={'mt-4'}>Souhaitez-vous utiliser l'envoi du lien de paiement pour valider le contrat&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'lienPaiementEmail'}
                                        value={'true'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'text-center'}
                                        onChange={(e) => changeLienPaiement(e.target.value)}
                                    />
                                </div>

                                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'lienPaiementEmail'}
                                        value={'false'}
                                        label={'Non'}
                                        validate={required}
                                        className={'text-center'}
                                        onChange={(e) => changeLienPaiement(e.target.value)}
                                    />
                                </div>
                            </div>
                            <p className={'m-0 text-grey'}><small>Un email de signature électronique partira automatiquement au client après le paiement du contrat d'assurance pour son vélo.</small></p>
                        </>
                    }
                </>
            }

            <ChampsObligatoires/>

            <div className={'mt-5'}>
                {
                    loading ? <SmallSpinner /> : <>

                        {
                            ibanIsCheck && <button
                                className={`${(!valid || loading) && 'disabled'} btn btn-primary btn-arrow medium-rounded float-right text-uppercase`}
                                data-id="btn-souscription"
                                type={"button"}
                                onClick={valid ? handleSubmit(form => postPaySubmit(form, 'devisVae')) : null}
                            >
                                {!isDirect && lienPaiementEmail === 'true' ? 'J\'envoie le lien de paiement au client' : 'JE FINALISE LA SOUSCRIPTION IMMEDIATEMENT'}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default Recapitulatif;
