import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Recapitulatif from "./Recapitulatif";
import {formValueSelector, reduxForm, change, clearFields, getFormValues} from "redux-form";
import {API_URL_WS_FORMULAIRE, APP_URL_APPLICATION} from '@amo/core/constants';
import {
    getIsDirect,
    getToken,
    getFormuleDevisInit,
    getActeGestionDemandeTarifInit, getSource, getCodeBelairApporteur1Init
} from "../../../../../../redux/selectors/initSession/initSession.selectors";
import {getLoadingEntity, getPrixTotal, getPrixTotalPromo} from "../../../../../../redux/selectors/ui/ui.selectors";
import {reformatFormules} from "../Tarif/reformatFormules";
import {POST_PAY, postPay} from "../../../../../../redux/actions/app/pay/pay.action";
import {postTarif, TARIF} from "../../../../../../redux/actions/app/tarif/tarif.actions";
import {loader} from "@amo/core/components/loaders";
import {isLoadedTarif} from "../../../../../../redux/selectors/tarif/tarif.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    const ville = selector(state, 'DemandeContrat[villeIBAN]')
    const cp = selector(state, 'DemandeContrat[cpIBAN]')

    return {
        source: getSource(state),
        codeBelair: getCodeBelairApporteur1Init(state),
        query: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        adresse: selector(state, 'DemandeTarif[ListePersonnes][0][Adresse1]'),
        nomPrenom: selector(state, 'DemandeTarif[ListePersonnes][0][Nom]')+ ' '+selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]'),
        nomTitulaire: selector(state, 'DemandeContrat[NomTitulaire]'),
        isDirect: getIsDirect(state),
        lienPaiementEmail: selector(state, 'lienPaiementEmail'),
        url_pay: {
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}/paiement`
        },
        loaded: getLoadingEntity(state, TARIF) === false,
        loading: getLoadingEntity(state, POST_PAY),
        tarifIsLoaded: isLoadedTarif(state),
        formule: getFormuleDevisInit(state) ? reformatFormules([ getFormuleDevisInit(state) ])[0] : {},
        prixTotal: selector(state, `DemandeTarif[Police][FractionnementChoisi]`) ? getPrixTotal(state) : 0,
        prixTotalPromo: selector(state, `DemandeTarif[Police][FractionnementChoisi]`) ? getPrixTotalPromo(state) : 0,
        fractionnement: selector(state, `DemandeTarif[Police][FractionnementChoisi]`),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        ibanIsCheck: Boolean(selector(state, 'DemandeContrat[BIC]')),
        apporteur: getCodeBelairApporteur1Init(state),
        formValues: getFormValues('devisVae')(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postPay,
    postTarif,
    changeValue: (field, value) => change('devisVae', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryTarif, url_pay, tarifIsLoaded, apporteur } = stateProps
    const { postPay, postTarif } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPaySubmit: (form) =>  postPay({query, form, url_pay}),
        load: () => !tarifIsLoaded && postTarif({query: queryTarif, ap1: apporteur})
    }
}

const RecapitulatifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(Recapitulatif)

export default RecapitulatifContainer
